import { ConferenceAd } from "../types/conference-ad";
import conferenceAds from "../../private-data/conference-ads/conference-ads.json";
import * as React from "react";

const conferenceAdForSlug = (conferenceSlug: string): ConferenceAd | null => {
  console.log(conferenceAds);
  console.log(
    conferenceAds.find(
      (conferenceAd) => conferenceAd.slug === "swift-connection-2024"
    ) || null
  );
  function findConferenceAdBySlug(slug: string): ConferenceAd | null {
    return (
      conferenceAds.find((conferenceAd) => conferenceAd.slug === slug) || null
    );
  }

  switch (conferenceSlug) {
    case "frenchkit":
    case "cocoaheads-paris":
    case "swift-connection":
      return findConferenceAdBySlug("swift-connection-2024");
    case "react-native-connection":
    case "react-native-paris":
      return findConferenceAdBySlug("react-native-connection-2024");
    case "flutter-connection":
      return findConferenceAdBySlug("flutter-connection-2024");

    default:
      return null;
  }
};

function DetailPageConferenceAd(props: { conferenceSlug: string }) {
  const conferenceAd = conferenceAdForSlug(props.conferenceSlug);
  if (conferenceAd == null) {
    return null;
  }
  return (
    <div
      key={conferenceAd.slug}
      className="group hover:bg-slate-700 rounded-lg overflow-clip p-4 hover:cursor-pointer transition ease-in-out delay-50 duration-500"
    >
      <a href={conferenceAd.link} target="_blank" className="group">
        <div className="text-xl font-bold mb-3 text-slate-300 group-hover:text-slate-50">
          Discover our next edition of {conferenceAd.title}
        </div>
        <img
          className="rounded-lg overflow-clip w-full max-w-prose mx-auto"
          src={`/conference-ads/${conferenceAd.image}`}
          alt={conferenceAd.title}
        />
      </a>
    </div>
  );
}

export default DetailPageConferenceAd;
