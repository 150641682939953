import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TalkTile from "../components/talk-tile";
import { SEO } from "../components/seo";
import DetailPageConferenceAd from "../components/detail-page-conference-ad";

type DataType = {
  data: Queries.EventPageInfoQuery;
};

type EventPageProps = {
  data: any;
  pageContext: {
    conference: string;
    edition?: any;
  };
};

export function Head({ data }: DataType) {
  return (
    <SEO
      title={`${data.eventInfo?.name} Videos, Material and Transcript`}
      pathname={data.eventInfo?.slug || undefined}
      image={data.eventInfo?.eventLogo?.publicURL || undefined}
      description={`${data.eventInfo?.name} Videos: videos, downloadable material and transcripts for the talks presented at the
      ${data.eventInfo?.name} conference.`}
      keywords={data.eventInfo?.name || undefined}
    />
  );
}

export default function EventPage({
  data: {
    eventInfo: { eventLogo, name, slug },
    allMarkdownRemark: { nodes },
  },
}: EventPageProps) {
  const Posts = nodes
    .filter((node: { frontmatter: { date: any } }) => !!node.frontmatter.date) // You can filter your posts based on some criteria
    .map((node) => <TalkTile key={node.id} post={node} eventPageMode={true} />);

  let centralImage: any;
  if (!eventLogo?.childImageSharp && eventLogo?.extension === "svg") {
    centralImage = (
      <img
        style={eventLogo.imageStyle}
        className="object-contain overflow-clip object-center h-full mx-auto py-16"
        src={eventLogo.publicURL}
        alt="Conference Logo"
      />
    );
  } else {
    const image = getImage(eventLogo);
    if (image != null) {
      centralImage = (
        <GatsbyImage
          style={eventLogo.imageStyle}
          className="object-contain overflow-clip object-center h-full mx-auto"
          imgClassName="overflow-hidden"
          alt="Conference Logo"
          objectFit="contain"
          image={image}
        />
      );
    } else {
      centralImage = <div></div>;
    }
  }

  return (
    <>
      <div className="fixed h-full w-full gradient bg-gradient-to-tr from-slate-900 from-70% to-slate-800 -z-10">
        &nbsp;
      </div>

      <div className="leading-normal tracking-normal text-white gradient bg-transparent">
        <nav
          id="header"
          className="fixed w-full z-30 top-0 text-white bg-black/30"
        >
          <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
            <div className="pl-4 flex items-center">
              <a
                className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <code>async</code>.{name}
              </a>
            </div>
          </div>
        </nav>
        <div className="pt-24 mb-24">
          <div className="container px-3 mx-auto h-96 mb-8 text-center overflow-clip">
            {centralImage && centralImage}
          </div>
          <div className="container mx-auto px-4">
            <h1 className="white text-5xl font-bold mb-8">{name} Videos</h1>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 grid-flow-row">
              {Posts}
            </div>
          </div>
          {/* Conference ad */}
          {slug && (
            <div className="container mx-auto px-4">
              <div className="mt-4 lg:mt-8">
                <DetailPageConferenceAd conferenceSlug={slug} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query EventPageInfo($conference: String, $edition: String) {
    eventInfo: eventsJson(slug: { eq: $conference }) {
      name
      slug
      eventLogo: logo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
        extension
        publicURL
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          conference: { eq: $conference }
          edition: { eq: $edition }
        }
      }
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        thumbnailImage {
          childImageSharp {
            gatsbyImageData(quality: 99)
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
          author
          tags
          video
          conference
          edition
        }
      }
    }
  }
`;
